<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-confirmar-ocorrencia"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Confirme!
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center black--text">
        Tem certeza que deseja criar uma ocorrência a partir desta leitura?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <btn-cancelar
          outlined
          @click="$emit('fechar')"
        />
        <v-btn
          color="success"
          outlined
          :loading="carregandoCriarOcorrencia"
          @click="criarOcorrencia"
        >
          <v-icon
            left
            v-text="'mdi-plus'"
          />
          Criar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import apiLeituras from '@/api/leituras'

  export default {

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      leitura: {
        type: Object,
        default: () => {},
      },
    },

    data () {
      return {
        carregandoEditarPlaca: false,
        placaEditar: null,
        carregandoCriarOcorrencia: false,
      }
    },

    methods: {

      async criarOcorrencia () {
        try {
          this.carregandoCriarOcorrencia = true
          const resposta = await apiLeituras.criarOcorrencia(this.leitura.id)

          this.$router.push(
            `/ocorrencias/${resposta.data.id}/editar`,
            () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }),
          )
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoCriarOcorrencia = false
        }
      },

    },
  }
</script>

<style lang="css">
  .dialog-confirmar-ocorrencia {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-confirmar-ocorrencia {
      width: 50% !important;
    }
  }
</style>
